<template>
  <div id="page-compras-por-pagar">
    <div>
      <v-breadcrumbs :items="breadcrumbs">
        <v-icon slot="divider">forward</v-icon>
      </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-col>
          <base-material-card
            color="primary"
            icon="library_books"
            title="Compras a Crédito"
            class="elevation-1 px-5 py-3"
          >
            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-container grid-list-md justify-center>
                  <v-row>
                    <v-col sm="3" md="3" lg="3">
                      <v-menu
                        offset-x
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        min-width="290px"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="model.fecha"
                            label="Fecha de Captura de la Factura"
                            color="secondary"
                            prepend-icon="event"
                            @blur="model.fecha = parse_date(model.fecha)"
                            :rules="[rules.required, valida_fecha]"
                          >
                          </v-text-field>
                        </template>

                        <v-date-picker
                          v-model="model.fecha"
                          color="secondary"
                          scrollable
                          locale="es-mx"
                          @input="menu1 = false"
                        >
                          <v-spacer />

                          <v-btn
                            color="secondary"
                            x-small
                            @click="menu1 = false"
                          >
                            Cancelar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col sm="3" md="3" lg="3">
                        <v-radio-group dense v-model="model.estatus_factura" row @change="searchCompras()">
                        <v-radio label="Por Pagar" value="Por Pagar"></v-radio>
                        <v-radio label="Pagadas" value="Pagada"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col sm="3" md="3" lg="3">
                      <v-btn
                        small
                        dark
                        color="blue"
                        @click.native="searchCompras()"
                        title="Buscar"
                        v-tippy
                      >
                        <v-icon>search</v-icon> Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="12" lg="12">
                        <v-row>
                            <v-col style="color:blue; text-align:right !important;"><h4>{{ model.estatus_factura }}</h4></v-col>
                        </v-row>
                      <v-client-table
                        :data="registros"
                        :columns="columns"
                        :options="options"
                      >
                        <template slot="noResults">
                          <v-alert :value="true" icon="info" color="info"
                            >No se encontró ningún registro</v-alert
                          >
                        </template>
                        <template slot="monto" slot-scope="props">
                            <div style="text-align:right">${{ get_total_orden(props.row.articulos) }}</div>
                        </template>
                        <template slot="monto_recibido" slot-scope="props">
                            <div style="text-align:right">${{ get_total_orden_recibida(props.row.articulos) }}</div>
                        </template>
                      </v-client-table>
                    </v-col> </v-row
                  ><br />
                </v-container>
              </v-form>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  components: {
  },
  mounted: function() {
    if (!this.verificaPermiso('t.compras.credito')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },

  created: function() {
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha = today;
  },
  data() {
    return {
        formas_pago:[],
        menu1:false,
      model_filters: "",
      fab: [],
      name: "datagrid",
      columns: [
        "fecha",
        "no_orden",
        "factura_fecha",
        "fecha_recibio_mercancia",
        "factura_fecha_vencimiento",
        "monto","monto_recibido",
        "factura_folio",
        "proveedor.nombre_proveedor",
        "proveedor.contacto",
        "proveedor.telefono_1",
        "proveedor.email",
        "factura_estatus"
      ],
      show_loading: true,
      options: {
        orderBy: {
            column: "no_orden",
            ascending: true
        },
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["fecha","no_orden",
        "factura_fecha","fecha_recibio_mercancia",
        "factura_fecha_vencimiento",
        "monto","monto_recibido",
        "factura_folio",
        "proveedor.nombre_proveedor",
        "proveedor.contacto",
        "proveedor.telefono_1",
        "proveedor.email",
        "factura_estatus"],
        headings: {
            "no_orden":"# Orden",
            "factura_fecha":"Fecha Factura",
            "fecha_recibio_mercancia":"Fecha Recibió Mercancía",
            "factura_fecha_vencimiento":"Fecha Vencimiento",
            "monto":"Total Factura",
            "monto_recibido":"Total Mercancia Recibida",
            "factura_folio":"Factura",
            "proveedor.nombre_proveedor":"Proveedor",
            "proveedor.contacto":"Vendedor",
            "proveedor.telefono_1":"Teléfono",
            "proveedor.email":"Correo",
            "factura_estatus":"Estatus"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Compras",
          disabled: true,
          href: ""
        },
        {
          text: "Compras a Crédito",
          href: ""
        }
      ],

      modal: false,

      update: false,
      model_update: "",
      model: {
        estatus_factura: "Por Pagar",
        fecha:"",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: [],
      row_selected:"",
      modal_editar: false,
      conteo_credito:0,
      conteo_debito: 0,
    };
  },
  methods: {
    editar: function(row){
        this.row_selected = row;
        this.modal_editar = true;
    },

    valida_fecha: function(fecha) {
        if (fecha == "Invalid date")
            return "Fecha no válida."
        else
            return true;
    },
    parse_date(date) {
        if (!date) return null;
        return window.moment(String(date)).format("YYYY-MM-DD");
    },
    searchCompras: function(){

        if (this.model.fecha != undefined && this.model.fecha != null && this.model.fecha != "" ) {
           window.dialogLoader.show('Espere un momento por favor..');
           var self = this;

            let data = {
                "selector":{
                    "type":"compras",
                    "factura_estatus": this.model.estatus_factura,
                    "fecha": {
                        "$gte": this.model.fecha,
                        "$lte": this.model.fecha+ "T23:59:59.999"
                    },
                },
                "fields":["no_orden","fecha","factura_fecha","fecha_recibio_mercancia","factura_fecha_vencimiento","monto","factura_folio",
                    "proveedor","factura_estatus","articulos"],
                "sort": ["type","fecha","factura_estatus"],
                "use_index":"_design/6f83d1eba7f1da19bd3f30c8dbfee15a7ec0b8bf"
            };

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';

            axios.post(url, data)
            .then(response => {

                self.registros = response.data.docs;
                for(var k in self.registros){
                    self.registros[k].fecha = self.registros[k].fecha.substring(0,10);
                    self.registros[k].factura_fecha = self.registros[k].factura_fecha.substring(0,10);
                    if(self.registros[k].fecha_recibio_mercancia != undefined){
                      self.registros[k].fecha_recibio_mercancia = self.registros[k].fecha_recibio_mercancia.substring(0,10);
                    }
                    self.registros[k].factura_fecha_vencimiento = self.registros[k].factura_fecha_vencimiento.substring(0,10);
                }


            }).catch( error => {
                console.log(error);
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar compras. Intente nuevamente.",
                    footer: ""
                  });
            }).then(()=>{
                window.dialogLoader.hide();
            });
        }

    },
    formatNumber: function (x) {
        if (x == undefined) return 0;
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
        return x;
    },
    get_total_orden: function(articulos){
            var total = 0;
        for(var k in articulos){
            total += parseFloat(articulos[k].precio_proveedor) * parseFloat(articulos[k].cantidad);
        }
        if(isNaN(total))
            return 0;
        else
            return this.formatNumber(total.toFixed(2));
    },
    get_total_orden_recibida: function(articulos){
            var total = 0;
            for(var k in articulos){
                if(articulos[k].precio_proveedor != null && articulos[k].precio_proveedor!="" && parseFloat(articulos[k].precio_proveedor)>0
                    && articulos[k].cantidad_recibida != null &&articulos[k].cantidad_recibida != "" && parseFloat(articulos[k].cantidad_recibida)>0){
                    total += parseFloat(articulos[k].precio_proveedor) * parseFloat(articulos[k].cantidad_recibida);
                }
            }
            if(isNaN(total))
                return 0;
            else
                return this.formatNumber(total.toFixed(2));                
        },

    ordenar_nombre: function( a, b ){
        if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
            return -1;
        }
        if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
            return 1;
        }
        return 0;
    },

  }
};
</script>
